/* Import head CSS */
@font-face {
  font-family: "A Little Sunshine";
  src: url("../fonts/a_little_sunshine_0.ttf");
}
/* line 5, ../../app/sass/components/_head.scss */
html {
  max-height: 100%;
  overflow: hidden;
}

/* line 9, ../../app/sass/components/_head.scss */
body {
  background-image: url("../../images/site/swirl_pattern.png");
  font-family: 'Open Sans',sans-serif;
  font-weight: 400;
  background-color: #FFFFFF;
}

/* line 15, ../../app/sass/components/_head.scss */
img {
  max-width: 100%;
}

/* line 18, ../../app/sass/components/_head.scss */
.custom-font {
  font-family: 'Open Sans', cursive;
  font-weight: 800;
  text-transform: uppercase;
}

/* line 24, ../../app/sass/components/_head.scss */
.scroller {
  position: relative;
  margin: 0 auto;
  max-height: 100%;
  overflow: hidden;
  display: block;
}

/* line 32, ../../app/sass/components/_head.scss */
.mCSB_inside > .mCSB_container {
  margin-right: 0;
}

/*
.miniscroll-thumb {
     background-color: #0e5066 !important;
}
.miniscroll-tracker {
    background-color: #1a8bb2 !important;
}
#miniscroll-target .miniscroll-thumb {
    background-color: #0e5066 !important;
}

#miniscroll-target .miniscroll-tracker {
    background-color: #1a8bb2 !important;
}
*/
/* line 50, ../../app/sass/components/_head.scss */
.navbar-fixed-top {
  background-color: rgba(0, 78, 153, 0.9);
  border-width: 0;
}

/* line 54, ../../app/sass/components/_head.scss */
.navbar-fixed-top #navbar {
  position: relative;
}

/* line 57, ../../app/sass/components/_head.scss */
.navbar-brand {
  background-color: rgba(255, 255, 255, 0.98);
  height: 120px;
  padding: 15px;
  position: absolute;
  width: 292px;
  transition: all 0.3s;
  z-index: 99999999999;
}

/* line 67, ../../app/sass/components/_head.scss */
.navbar-brand > img {
  padding: 3px 10px;
}

/* line 70, ../../app/sass/components/_head.scss */
.navbar-brand::after {
  background-color: transparent;
  border-left: 14.7rem solid transparent;
  border-right: 14.7rem solid transparent;
  border-top: 24px solid rgba(255, 255, 255, 0.98);
  content: "";
  display: inline-block;
  height: 0;
  margin-left: -15px;
  margin-top: 15px;
  padding: 5px 0;
  vertical-align: middle;
  transition: all 0.3s;
}

/* line 84, ../../app/sass/components/_head.scss */
#bg-logo {
  background-color: transparent;
  border-left: 14.5rem solid transparent;
  border-right: 14.5rem solid transparent;
  border-top: 28px solid rgba(0, 0, 0, 0.06);
  left: 0;
  margin-top: 121px;
  position: absolute;
  top: 0;
  width: 292px;
  z-index: -1;
}

/* line 97, ../../app/sass/components/_head.scss */
.navbar-brand.scroll2 > #bg-logo {
  display: none;
}

/* line 101, ../../app/sass/components/_head.scss */
.navbar-brand.scroll {
  background-color: rgba(255, 255, 255, 0);
  background-image: url("../../images/site/logo-branca-sem-sombra.png");
  background-position: 0 3px;
  background-repeat: no-repeat;
  background-size: auto 86%;
  box-shadow: none;
  height: 100%;
  text-align: center;
}

/* line 111, ../../app/sass/components/_head.scss */
.navbar-brand.scroll::after {
  background: transparent none repeat scroll 0 0;
  border: medium none;
}

/* line 116, ../../app/sass/components/_head.scss */
.navbar-brand.scroll2 {
  padding: 58px 60px 0;
  top: -56px;
  transition: all 0.3s;
}

/* line 121, ../../app/sass/components/_head.scss */
.navbar-brand.scroll2::after {
  margin-left: -60px;
  margin-top: 3px;
  transition: all 0.3s;
}

/* line 126, ../../app/sass/components/_head.scss */
.navbar-brand.scroll > img {
  display: none;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}

/* line 134, ../../app/sass/components/_head.scss */
.navbar-toggle {
  background-image: url("../../images/site/menu.svg");
  background-size: 36px auto;
}

/* line 138, ../../app/sass/components/_head.scss */
.nav.navbar-nav {
  padding: 0px 25px;
  margin: 0 0 0 275px;
}

/* line 142, ../../app/sass/components/_head.scss */
.navbar-nav > li {
  text-align: center;
  min-width: 135px;
}

/* line 146, ../../app/sass/components/_head.scss */
.navbar-nav > li > a {
  padding: 20px 10px;
}

/* line 149, ../../app/sass/components/_head.scss */
.navbar-nav > li.active {
  border-bottom: 3px solid #C77618;
  background: #C77618;
}

/* line 153, ../../app/sass/components/_head.scss */
.navbar-nav > li.active::after {
  background-color: transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 12px solid #C77618;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: -15px;
  margin-top: 0;
  padding: 5px 0;
  position: absolute;
  vertical-align: middle;
}

/* line 167, ../../app/sass/components/_head.scss */
.navbar-nav > li:not(:last-child) > a {
  border-right: 1px solid rgba(0, 78, 153, 0.9);
}

/* line 170, ../../app/sass/components/_head.scss */
.navbar-nav > li > a {
  color: #ffffff;
  font-family: 'Open Sans',sans-serif;
  font-size: 1.5rem;
  font-stretch: condensed;
  font-weight: 500;
  transition: all 0.3s;
  text-transform: uppercase;
}

/* line 179, ../../app/sass/components/_head.scss */
.navbar-nav > li > a:hover {
  background-color: rgba(246, 130, 0, 0.8);
  transition: all 0.3s;
}

/* line 183, ../../app/sass/components/_head.scss */
.navbar-nav > li > a:focus {
  background: transparent none repeat scroll 0 0;
  border: 1px solid rgba(0, 78, 153, 0.98);
  transition: all 0.3s;
}

/* line 188, ../../app/sass/components/_head.scss */
.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: rgba(246, 130, 0, 0.8);
  border: medium none;
  transition: all 0.3s;
}

/* line 193, ../../app/sass/components/_head.scss */
.navbar-nav .dropdown-menu {
  background-color: transparent;
  border: medium none;
  box-shadow: none;
  color: #ffffff;
  min-width: 50px;
  padding: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: all 0.3s;
  display: block;
}

/* line 207, ../../app/sass/components/_head.scss */
.navbar-nav .open > .dropdown-menu {
  height: 300px;
  transition: all 0.3s;
}

/* line 212, ../../app/sass/components/_head.scss */
.dropdown-menu > li {
  background: rgba(246, 130, 0, 0.8) none repeat scroll 0 0;
  padding: 5px 0;
  text-align: center;
}

/* line 217, ../../app/sass/components/_head.scss */
.navbar-nav .dropdown-menu::after {
  background-color: transparent;
  border-left: 6.1rem solid transparent;
  border-right: 6.1rem solid transparent;
  border-top: 15px solid rgba(246, 130, 0, 0.8);
  content: "";
  display: inline-block;
  height: 0;
  margin-top: 0;
  padding: 5px 0;
  vertical-align: middle;
  width: 100%;
}

/* line 230, ../../app/sass/components/_head.scss */
.navbar-nav .dropdown-menu > li > a {
  color: #ffffff;
  white-space: normal;
}

/* line 234, ../../app/sass/components/_head.scss */
.navbar-nav .dropdown-menu > li > a:hover, .navbar-nav .dropdown-menu > li > a:focus {
  background-color: rgba(255, 255, 255, 0.8);
  color: #004e99;
  font-weight: 400;
}

/* line 239, ../../app/sass/components/_head.scss */
.dropdown-menu > li.acompanhamento-psicologico > a {
  font-size: 1.35rem !important;
  padding: 5px !important;
}

/* line 243, ../../app/sass/components/_head.scss */
#restrict-area {
  margin-right: 40px;
  margin-top: 4px;
  padding: 3px 10px;
  border-radius: 3px;
}

/* line 249, ../../app/sass/components/_head.scss */
#restrict-area a {
  color: #fff;
}

/* line 253, ../../app/sass/components/_head.scss */
.popover {
  border-radius: 0;
  z-index: 100000000000;
}

/* line 259, ../../app/sass/components/_head.scss */
#container-form-area-restrita {
  display: none;
}

/* line 263, ../../app/sass/components/_head.scss */
#container-form-area-restrita .form-control {
  margin-top: 5px;
}

/* line 267, ../../app/sass/components/_head.scss */
#container-form-area-restrita .input-group {
  margin: 10px 0 15px;
}

/* line 271, ../../app/sass/components/_head.scss */
#container-form-area-restrita .input-group button.btn {
  background-color: rgba(246, 130, 0, 0.8);
  color: #fff;
}

/* line 276, ../../app/sass/components/_head.scss */
.nav-tabs {
  border-bottom: 1px solid rgba(0, 78, 153, 0.9);
  margin-top: 5px;
  margin-bottom: 15px;
}

/* line 282, ../../app/sass/components/_head.scss */
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #fff;
  cursor: default;
  background-color: rgba(0, 78, 153, 0.9);
  border: 1px solid rgba(0, 78, 153, 0.9);
  border-bottom-color: transparent;
}

/* line 289, ../../app/sass/components/_head.scss */
.nav-tabs > li > a {
  color: rgba(0, 78, 153, 0.9);
}

/* line 293, ../../app/sass/components/_head.scss */
.search-box {
  height: 100%;
  position: absolute;
  right: 0;
  top: 11px;
  width: auto;
}

/* line 300, ../../app/sass/components/_head.scss */
.search-box.open {
  top: 0;
  width: calc(100% - 290px);
}

/* line 304, ../../app/sass/components/_head.scss */
.search-box .form-inline {
  float: right;
  opacity: 0;
  transition: all 0.3s ease 0s;
  width: 0;
}

/* line 310, ../../app/sass/components/_head.scss */
.form-inline .form-group {
  display: inline-block;
  height: calc(100% - 10px);
  margin: 5px 0;
  vertical-align: middle;
  width: 100%;
}

/* line 317, ../../app/sass/components/_head.scss */
.search-box .form-inline.open {
  height: 100%;
  opacity: 1;
  transition: all 0.3s ease 0s;
  width: 100%;
}

/* line 324, ../../app/sass/components/_head.scss */
.search-box .form-inline .input-group {
  display: inline-flex;
  width: 100%;
  height: 100%;
}

/* line 329, ../../app/sass/components/_head.scss */
.search-box .form-inline input {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #333;
  width: 100%;
  height: 100%;
  max-height: 55px;
}

/* line 337, ../../app/sass/components/_head.scss */
.search-box .form-inline .input-group > button {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 0 4px 4px 0;
  max-height: 55px;
}

/* Import links-fixed CSS */
/* line 1, ../../app/sass/components/_links-fixed.scss */
.links-fixed {
  margin-top: 150px;
  position: fixed;
  width: 100%;
  z-index: 2147483647;
}

/* line 8, ../../app/sass/components/_links-fixed.scss */
.social-media.social-media-fixed ul li a {
  display: flex;
}

/* line 12, ../../app/sass/components/_links-fixed.scss */
.links-fixed .social-media.social-media-fixed li a svg {
  width: 54px;
  height: 54px;
}

/* line 16, ../../app/sass/components/_links-fixed.scss */
.links-fixed .bg-transparent {
  fill: rgba(255, 255, 255, 0.2);
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
}

/* line 21, ../../app/sass/components/_links-fixed.scss */
.links-fixed .icon-svg-content {
  fill: #333;
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
}

/* line 27, ../../app/sass/components/_links-fixed.scss */
.links-fixed svg:hover .icon-svg-content {
  fill: #ffffff;
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
}

/* line 32, ../../app/sass/components/_links-fixed.scss */
.links-fixed svg#twitter:hover .bg-transparent {
  background-color: #50abf1;
  fill: #50abf1;
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
}

/* line 38, ../../app/sass/components/_links-fixed.scss */
.links-fixed svg#facebook:hover .bg-transparent {
  background-color: #3d5a98;
  fill: #3d5a98;
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
}

/* line 44, ../../app/sass/components/_links-fixed.scss */
.links-fixed svg#instagram:hover .bg-transparent {
  background-color: #507fa7;
  fill: #507fa7;
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
}

/* line 50, ../../app/sass/components/_links-fixed.scss */
.links-fixed svg#youtube:hover .bg-transparent {
  background-color: #dc472e;
  fill: #dc472e;
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
}

/* line 56, ../../app/sass/components/_links-fixed.scss */
.social-media.social-media-fixed {
  left: 0;
  position: absolute;
  z-index: 10000;
}

/* line 61, ../../app/sass/components/_links-fixed.scss */
.social-media-fixed > ul > li {
  width: 100px;
}

/* line 64, ../../app/sass/components/_links-fixed.scss */
.fast-links-fixed ul > li {
  display: table;
  height: 59px;
  margin: 23px 0;
  padding: 5px 15px;
  position: relative;
  width: auto;
}

/* line 72, ../../app/sass/components/_links-fixed.scss */
.fast-links-fixed ul > li > div.links {
  background-color: rgba(121, 124, 128, 0.75);
  display: block;
  margin: 10px 0;
  padding: 10px;
  position: absolute;
  right: -205px;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  width: 280px;
}

/* line 83, ../../app/sass/components/_links-fixed.scss */
.fast-links-fixed ul > li > div.links::before {
  border-bottom: 37px solid transparent;
  border-right: 33px solid rgba(121, 124, 128, 0.75);
  border-top: 38px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  left: -33px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  vertical-align: middle;
  width: 0;
  transition: all 0.3s;
}

/* line 98, ../../app/sass/components/_links-fixed.scss */
.fast-links-fixed ul li a span {
  color: #ffffff;
  display: block;
  float: left;
  font-size: 1.5rem;
  margin: 0 10px 0 15px;
  padding-top: 15px;
  text-decoration: none;
  border-bottom: 3px solid #ffffff;
  float: left;
}

/* line 109, ../../app/sass/components/_links-fixed.scss */
.fast-links-fixed ul li a svg.fast-link {
  display: inline;
  float: left;
  vertical-align: middle;
  fill: #fff;
  width: 54px;
}

/* line 116, ../../app/sass/components/_links-fixed.scss */
.fast-links-fixed ul li div.links:hover {
  right: 0;
  background-color: rgba(246, 130, 0, 0.8);
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
}

/* line 122, ../../app/sass/components/_links-fixed.scss */
.fast-links-fixed ul > li > div.links:hover::before {
  border-right-color: rgba(246, 130, 0, 0.8);
  -webkit-transition: all 0.3s;
  /* Safari */
  transition: all 0.3s;
}

/* line 127, ../../app/sass/components/_links-fixed.scss */
.fast-links-fixed {
  position: absolute;
  right: 0;
  z-index: 10000;
}

/* line 7, ../../app/sass/home.scss */
.main-slider {
  margin-top: 0px;
  padding-bottom: 5px;
}

/* line 11, ../../app/sass/home.scss */
.main-slider .carousel-caption h2 {
  color: #ffffff;
  margin: 5px 0 25px 0 !important;
}

/* line 16, ../../app/sass/home.scss */
.main-slider .carousel-caption {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.7);
  width: 100%;
}

/* line 24, ../../app/sass/home.scss */
.carousel-caption.hidden_text {
  display: none;
}

/* line 27, ../../app/sass/home.scss */
.carousel-inner .item img {
  width: 100%;
}

/* line 30, ../../app/sass/home.scss */
.carousel-inner .item img {
  width: 100%;
}

/* line 33, ../../app/sass/home.scss */
.carousel-indicators li {
  border: 1px solid #ccc;
}

/* line 36, ../../app/sass/home.scss */
.container.boxes-home {
  margin: 25px auto 45px;
  text-align: center;
}

/* line 40, ../../app/sass/home.scss */
.boxes-home .box .iconbox {
  font-size: 7rem;
  text-align: center;
}

/* line 44, ../../app/sass/home.scss */
.boxes-home .box {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.3);
  color: #3d3d3d;
  padding: 2rem;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

/* line 53, ../../app/sass/home.scss */
.boxes-home .box .title-box {
  margin-top: 10px;
}

/* line 56, ../../app/sass/home.scss */
.boxes-home .box .border-svg {
  background-color: rgba(0, 78, 153, 0.85);
  border: 2px solid #ffffff;
  border-radius: 100%;
  display: table;
  margin: 0 auto 15px;
  padding: 20px;
}

/* line 64, ../../app/sass/home.scss */
.boxes-home .box h3 {
  font-size: 2.3rem;
  margin-top: 0;
  padding-top: 9px;
  text-transform: uppercase;
}

/* line 70, ../../app/sass/home.scss */
.boxes-home .box .fast-link {
  fill: #ffffff;
  height: 65px;
}

/* line 74, ../../app/sass/home.scss */
.boxes-home .box p {
  clear: both;
  display: block;
  width: 100%;
}

/* line 79, ../../app/sass/home.scss */
.boxes-home .box .text-hover {
  background: rgba(255, 255, 255, 0) none repeat scroll 0 0;
  bottom: 0;
  color: #ffffff;
  display: block;
  font-size: 1.3rem;
  height: 20px;
  left: 0;
  overflow: hidden;
  padding: 0 25px;
  position: absolute;
  right: 0;
  text-align: justify;
  transition: all 0.5s ease 0s;
  width: 100%;
}

/* line 95, ../../app/sass/home.scss */
.boxes-home .box .text-hover .title-box {
  display: table;
  float: none;
  margin: 15% auto 0;
  vertical-align: middle;
}

/* line 101, ../../app/sass/home.scss */
.boxes-home .box .text-hover .fast-link {
  fill: #ffffff;
  float: left;
  height: auto;
  width: 36px;
}

/* line 107, ../../app/sass/home.scss */
.boxes-home .box .text-hover h3 {
  float: left;
  font-size: 1.8rem;
  margin: 10px 10px 15px;
  padding: 0;
  text-transform: uppercase;
}

/* line 114, ../../app/sass/home.scss */
.boxes-home .box .text-hover a.btn {
  border: 2px solid #ffffff;
  border-radius: 0;
  color: #ffffff;
  display: table;
  float: none;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 20px auto 0;
  text-align: center;
  text-transform: uppercase;
}

/* line 126, ../../app/sass/home.scss */
.boxes-home .box:hover .text-hover {
  background-color: rgba(0, 78, 153, 0.98);
  height: 100%;
  transition: all 0.5s ease 0s;
}

/* line 131, ../../app/sass/home.scss */
#parallaxBar {
  background: transparent url("../../images/site/picjumbo.png") repeat fixed 50% 0;
  height: auto !important;
  position: relative;
}

/* line 136, ../../app/sass/home.scss */
.divisor {
  height: 3px;
  margin: 5px auto;
  width: 30%;
}

/* line 141, ../../app/sass/home.scss */
.testimonials {
  background-color: rgba(246, 130, 0, 0.8);
  color: #ffffff;
  font-size: 1.7rem;
  height: 100%;
  text-align: center;
}

/* line 148, ../../app/sass/home.scss */
.testimonials h1.custom-font {
  font-size: 2.8rem;
}

/* line 151, ../../app/sass/home.scss */
.testimonials .carousel {
  background: transparent none repeat scroll 0 0;
}

/* line 154, ../../app/sass/home.scss */
.testimonials .carousel .carousel-control {
  background-image: none;
}

/* line 157, ../../app/sass/home.scss */
.carousel-control.right {
  right: -150px;
}

/* line 160, ../../app/sass/home.scss */
.carousel-control.left {
  left: -150px;
}

/* line 163, ../../app/sass/home.scss */
.testimonials .carousel .carousel-inner {
  margin: 0 auto;
  text-align: justify;
  width: 100%;
}

/* line 168, ../../app/sass/home.scss */
.testimonials .carousel.testimonial.slide {
  min-height: 200px;
  padding-top: 50px;
}

/* line 172, ../../app/sass/home.scss */
.testimonials .carousel .item .inner {
  -webkit-box-align: center;
  -webkit-align-itens: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -webkit-box-pack: center;
}

/* line 183, ../../app/sass/home.scss */
.testimonials .carousel .item .image-testimonial {
  float: left;
  margin-right: 25px;
  width: 11%;
}

/* line 188, ../../app/sass/home.scss */
.testimonials .text-testimonial h5 {
  padding-top: 25px;
  text-transform: uppercase;
}

/* line 192, ../../app/sass/home.scss */
.container.news-and-highlights {
  background-color: #ffffff;
  box-shadow: 1px 0 8px rgba(51, 51, 51, 0.45);
  margin: 20px auto 0;
  max-width: 95%;
  padding: 15px 30px 50px;
  width: auto;
}

/* line 200, ../../app/sass/home.scss */
#news, #highlights {
  padding: 0 30px;
}

/* line 203, ../../app/sass/home.scss */
.item-highlights-home {
  margin-bottom: 11px;
  padding: 0 19px;
}

/* line 207, ../../app/sass/home.scss */
.box-highlight > h2 > a {
  color: #f68200;
  font-size: 2.8rem;
}

/* line 211, ../../app/sass/home.scss */
.abstract-highlights {
  background-color: rgba(246, 130, 0, 0.8);
  bottom: 1rem;
  color: #ffffff;
  padding: 1rem;
  position: absolute;
  right: 0;
  text-align: right;
  width: 100%;
}

/* line 221, ../../app/sass/home.scss */
.abstract-highlights > span {
  display: block;
}

/* line 224, ../../app/sass/home.scss */
.item-highlights-home > h4 {
  color: #f68200;
  font-size: 2rem;
  margin-bottom: 0;
  margin-left: 15px;
  margin-right: 0;
  text-align: right;
  height: 45px;
  overflow: hidden;
}

/* line 234, ../../app/sass/home.scss */
.img-highlights > img {
  border: 1px solid #eeeeee;
}

/* line 237, ../../app/sass/home.scss */
.box-news > h2 > a {
  color: #004e99;
  font-size: 2.8rem;
}

/* line 241, ../../app/sass/home.scss */
.row.list-news.list-unstyled {
  margin-top: 38px;
}

/* line 244, ../../app/sass/home.scss */
.item-news-home .title-news > h4 {
  color: #004e99;
  font-size: 2rem;
  margin-bottom: 0;
  text-align: left;
}

/* line 250, ../../app/sass/home.scss */
.img-news .img-responsive {
  padding: 1rem 0;
}

/* line 253, ../../app/sass/home.scss */
.img-highlights {
  padding: 5px 0 9px 0;
  position: relative;
}

/* line 257, ../../app/sass/home.scss */
.item-news-home .abstract-news {
  height: 100%;
  padding: 15px 5px;
  text-align: justify;
}

/* line 262, ../../app/sass/home.scss */
.item-news-home .abstract-news > .btn {
  border: 1px solid #004e99;
  color: #004e99;
  margin-top: 30px;
}

/* line 267, ../../app/sass/home.scss */
.list-news li {
  overflow: hidden;
  padding: 10px 16px;
}

/* line 271, ../../app/sass/home.scss */
.list-news li:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

/* line 274, ../../app/sass/home.scss */
.list-news li .img-news {
  padding: 0;
}

/* line 277, ../../app/sass/home.scss */
.list-news li .how-older {
  font-size: 1.3rem;
  margin: 5px 0 0;
  float: right;
  padding-right: 15px;
}

/* line 283, ../../app/sass/home.scss */
.list-news li .how-older .icons8-Clock {
  display: inline-block;
  fill: #004e99;
  height: 28px;
  vertical-align: middle;
  width: 26px;
}

/* line 290, ../../app/sass/home.scss */
.list-news li .how-older > span {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

/* line 295, ../../app/sass/home.scss */
.list-news li h4 {
  display: table;
  float: left;
  font-size: 1.3rem;
  margin-bottom: 0;
  padding-left: 0;
  text-transform: uppercase;
  width: auto;
}

/* line 304, ../../app/sass/home.scss */
.list-news li .info-new {
  display: block;
  padding: 0;
  text-align: justify;
}

/* line 309, ../../app/sass/home.scss */
.list-news li .info-new h3 {
  color: #004e99;
  margin-top: 0;
}

/* line 313, ../../app/sass/home.scss */
.list-news li .info-new a {
  color: #004e99;
  display: block;
  float: right;
  font-weight: 700;
  margin: 10px 0;
  text-align: right;
  width: 100%;
}

/* Import map CSS */
/* line 2, ../../app/sass/components/_map.scss */
.map {
  margin-top: 50px;
  position: relative;
}

/* line 6, ../../app/sass/components/_map.scss */
.map .divisor {
  background-color: #f68200;
  height: 5px;
  left: calc(50% - 15%);
  position: absolute;
  top: 5px;
  z-index: 1000;
}

/* line 14, ../../app/sass/components/_map.scss */
#mapa {
  border: 1px solid #cccccc;
  height: 500px;
  width: 100%;
}

/* line 19, ../../app/sass/components/_map.scss */
.gm-style-iw {
  box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
  left: 70px !important;
  overflow: visible !important;
  top: 91px !important;
  width: 350px !important;
}

/* line 26, ../../app/sass/components/_map.scss */
.gm-style-iw::before {
  border-bottom: 30px solid transparent;
  border-right: 49px solid #d58400;
  border-top: 29px solid transparent;
  content: "";
  display: block;
  height: 0;
  left: -48px;
  margin-right: 0;
  margin-top: 0;
  position: absolute;
  top: 81px;
  vertical-align: top;
  width: 0;
}

/* line 41, ../../app/sass/components/_map.scss */
.gm-style .gm-style-iw + div {
  right: -5px !important;
  top: 98px !important;
}

/* line 45, ../../app/sass/components/_map.scss */
#info-maps {
  background-color: rgba(213, 132, 0, 0.95);
  border: 1px solid #f68200;
  color: #ffffff;
  padding: 15px;
  text-align: left;
}

/* line 52, ../../app/sass/components/_map.scss */
#info-maps > h2 {
  border-bottom: 3px solid #ffffff;
  display: table;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2rem;
}

/* line 60, ../../app/sass/components/_map.scss */
#info-maps > h2 > a {
  color: #fff;
}

/* line 63, ../../app/sass/components/_map.scss */
#info-maps .contacts.list-unstyled {
  font-size: 1.8rem;
  margin-top: 20px;
}

/* line 67, ../../app/sass/components/_map.scss */
#info-maps .contacts.list-unstyled > li {
  padding: 8px 15px;
}

/* line 70, ../../app/sass/components/_map.scss */
#info-maps ul.contacts li span.glyphicon {
  font-size: 2.5rem;
  margin-right: 10px;
}

/* line 74, ../../app/sass/components/_map.scss */
.contacts.list-unstyled span {
  display: inline;
  vertical-align: middle;
}

/* Import footer CSS */
/* line 1, ../../app/sass/components/_footer.scss */
footer {
  color: #ffffff;
}

/* line 4, ../../app/sass/components/_footer.scss */
footer #parallaxFooter {
  background: transparent url("../../images/site/picjumbo.png") repeat fixed 50% 0;
  height: auto;
  position: relative;
}

/* line 9, ../../app/sass/components/_footer.scss */
footer #parallaxFooter .container-fluid {
  background-color: rgba(0, 78, 153, 0.9);
}

/* line 12, ../../app/sass/components/_footer.scss */
footer .divisor {
  height: 3px;
  margin: 5px auto;
  width: 30%;
}

/* line 17, ../../app/sass/components/_footer.scss */
footer p {
  margin: 0;
}

/* line 20, ../../app/sass/components/_footer.scss */
footer .links-footer {
  display: block;
  padding: 30px 0;
}

/* line 24, ../../app/sass/components/_footer.scss */
footer .links-footer ul {
  color: #ffffff;
  list-style: outside none none;
  padding-left: 10px;
}

/* line 29, ../../app/sass/components/_footer.scss */
footer .links-footer .map-site ul {
  padding-left: 5px;
}

/* line 32, ../../app/sass/components/_footer.scss */
footer .links-footer .map-site ul li a {
  color: #ffffff;
}

/* line 35, ../../app/sass/components/_footer.scss */
footer .links-footer .social-media-footer {
  margin-top: 10px;
  padding: 4px;
}

/* line 39, ../../app/sass/components/_footer.scss */
footer .links-footer .social-media-footer ul li {
  padding: 0;
}

/* line 42, ../../app/sass/components/_footer.scss */
footer .links-footer .social-media-footer > ul > li > a > img {
  height: auto;
  width: 100%;
}

/* line 47, ../../app/sass/components/_footer.scss */
footer .copyright {
  background-color: #004e99;
  display: block;
  padding: 5px;
}

/*@media(min-width: @screen-xs-min) {
    footer .brand-footer{
        display: none;
    }
    footer .social-media.social-media-footer {
        display: block;
        margin-bottom: 20px;
    } 
}
*/
/* Import responsive default CSS */
/* Default */
@media (max-width: 1599px) {
  /* line 3, ../../app/sass/components/_responsive.scss */
  #restrict-area {
    margin-right: 35px;
  }

  /* line 6, ../../app/sass/components/_responsive.scss */
  #restrict-area > a > span {
    display: none;
  }

  /* line 9, ../../app/sass/components/_responsive.scss */
  .navbar .container {
    width: 1270px;
  }
}
@media (max-width: 1280px) {
  /* line 15, ../../app/sass/components/_responsive.scss */
  .navbar .container {
    width: 95%;
  }

  /* line 18, ../../app/sass/components/_responsive.scss */
  #restrict-area {
    position: absolute;
    right: 30px;
    margin-right: 0;
  }

  /* line 24, ../../app/sass/components/_responsive.scss */
  .navbar-nav > li {
    min-width: 120px;
  }

  /* line 28, ../../app/sass/components/_responsive.scss */
  .navbar-nav > li > a {
    font-size: 1.6rem;
  }
}
@media (max-width: 1195px) {
  /* line 35, ../../app/sass/components/_responsive.scss */
  .navbar-brand {
    width: 200px;
    height: 85px;
  }

  /* line 40, ../../app/sass/components/_responsive.scss */
  .navbar-brand.scroll2 {
    height: 113px;
    width: 180px;
    padding: 55px 22px;
    top: -50px;
  }

  /* line 47, ../../app/sass/components/_responsive.scss */
  .navbar-brand::after {
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    margin-top: 12px;
  }

  /* line 52, ../../app/sass/components/_responsive.scss */
  #bg-logo {
    background-color: transparent;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    border-top: 26px solid rgba(0, 0, 0, 0.06);
    margin-top: 85px;
    width: 100px;
  }

  /* line 61, ../../app/sass/components/_responsive.scss */
  .navbar-brand.scroll2::after {
    border: none;
  }

  /* line 65, ../../app/sass/components/_responsive.scss */
  .nav.navbar-nav {
    margin: 0 0 0 170px;
  }

  /* line 69, ../../app/sass/components/_responsive.scss */
  .brand-footer a img {
    max-width: 180px;
  }

  /* line 72, ../../app/sass/components/_responsive.scss */
  .social-media-footer ul li a img {
    max-width: 39px;
  }
}
@media (max-width: 995px) {
  /* line 79, ../../app/sass/components/_responsive.scss */
  .navbar-brand {
    position: relative;
  }

  /* line 83, ../../app/sass/components/_responsive.scss */
  .navbar-brand.scroll2 {
    height: 50px;
    padding: 3px 31px 0;
    top: inherit;
  }

  /* line 89, ../../app/sass/components/_responsive.scss */
  .navbar-brand::after, .navbar-brand.scroll2::after {
    border: none !important;
  }

  /* line 93, ../../app/sass/components/_responsive.scss */
  .navbar .container {
    position: relative;
  }

  /* line 96, ../../app/sass/components/_responsive.scss */
  .navbar #restrict-area {
    position: absolute;
    top: 0;
    right: 45px;
  }

  /* line 101, ../../app/sass/components/_responsive.scss */
  ul.nav.navbar-nav {
    margin: 10px auto 0;
    padding: 5px 0;
    width: 100%;
  }

  /* line 106, ../../app/sass/components/_responsive.scss */
  .navbar-nav > li {
    min-width: 115px;
  }

  /* line 109, ../../app/sass/components/_responsive.scss */
  .navbar-brand svg {
    height: auto;
    width: 200px;
  }

  /* line 113, ../../app/sass/components/_responsive.scss */
  #fundo-logo, #svg-logo linearGradient {
    display: none;
  }

  /* line 116, ../../app/sass/components/_responsive.scss */
  #svg-logo path {
    fill: #ffffff;
  }

  /* line 120, ../../app/sass/components/_responsive.scss */
  .banner-fixed {
    padding-top: 16.9%;
  }

  /* line 124, ../../app/sass/components/_responsive.scss */
  #links-fixed {
    display: none;
  }

  /* line 128, ../../app/sass/components/_responsive.scss */
  .brand-footer a img {
    max-width: 180px;
  }

  /* line 131, ../../app/sass/components/_responsive.scss */
  .social-media-footer ul li a img {
    max-width: 39px;
  }
}
@media (max-width: 767px) {
  /* line 137, ../../app/sass/components/_responsive.scss */
  #navbar {
    position: relative;
    height: 100px;
  }

  /* line 141, ../../app/sass/components/_responsive.scss */
  .navbar-header {
    height: 50px;
  }

  /* line 144, ../../app/sass/components/_responsive.scss */
  .navbar-brand {
    position: absolute;
    background-size: calc(100% - 30px) auto;
    background-position: 15px 10px;
    width: 50%;
    height: 60px;
  }

  /*
  .navbar-brand::after {
      width: 50%;
      border-left: 7.5rem solid rgba(0, 0, 0, 0);
      border-right: 7.5rem solid rgba(0, 0, 0, 0);
      border-top: 15px solid rgba(255, 255, 255, 0.98);
      margin-top: 4px;
  } 
  
  #bg-logo {
      background-color: transparent;
      border-left: 7.5rem solid transparent;
      border-right: 7.5rem solid transparent;
      border-top: 28px solid rgba(0, 0, 0, 0.06);
      margin-top: 50px;
  }
  */
  /* line 169, ../../app/sass/components/_responsive.scss */
  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: none;
  }

  /* line 173, ../../app/sass/components/_responsive.scss */
  .navbar #restrict-area {
    display: block;
    float: none;
    margin: 0 auto;
    position: relative;
    left: -50%;
    text-align: center;
    width: 50%;
    transition: all 0.3s;
  }

  /* line 183, ../../app/sass/components/_responsive.scss */
  .navbar #restrict-area:hover {
    background-color: rgba(246, 130, 0, 0.8);
    transition: all 0.3s;
  }

  /* line 188, ../../app/sass/components/_responsive.scss */
  #restrict-area a {
    color: #ffffff;
    display: block;
    padding: 15px;
    width: 100%;
  }

  /* line 195, ../../app/sass/components/_responsive.scss */
  ul.nav.navbar-nav {
    display: table;
    margin: auto 0;
    padding: 5px 0;
    position: relative;
    width: 100%;
  }

  /* line 202, ../../app/sass/components/_responsive.scss */
  .navbar-nav .dropdown-menu::after {
    display: none;
  }

  /* line 206, ../../app/sass/components/_responsive.scss */
  .navbar-nav .dropdown-menu {
    height: auto;
    display: none;
  }

  /* line 211, ../../app/sass/components/_responsive.scss */
  .navbar-nav .open > .dropdown-menu {
    height: auto;
    display: block;
  }

  /* line 216, ../../app/sass/components/_responsive.scss */
  .navbar-brand {
    position: absolute;
  }

  /* line 220, ../../app/sass/components/_responsive.scss */
  .search-box {
    height: auto;
    position: absolute;
    right: 7%;
    top: 26px;
    text-align: center;
    width: 30%;
  }

  /* line 229, ../../app/sass/components/_responsive.scss */
  .search-box.open {
    width: 100%;
    position: relative;
    margin-top: 25px;
    right: 0;
  }

  /* line 236, ../../app/sass/components/_responsive.scss */
  #banner-fixed {
    display: none;
  }

  /* line 240, ../../app/sass/components/_responsive.scss */
  section#breadcrumb {
    padding-top: 50px;
  }

  /* line 244, ../../app/sass/components/_responsive.scss */
  footer .links-footer {
    padding: 0;
  }

  /* line 248, ../../app/sass/components/_responsive.scss */
  footer .links-footer div > h3 {
    font-size: 18px;
  }

  /* line 252, ../../app/sass/components/_responsive.scss */
  .brand-footer a img {
    max-width: 100%;
  }
}
/* Home */
@media (max-width: 995px) {
  /* line 259, ../../app/sass/components/_responsive.scss */
  .boxes-home .box h3 {
    font-size: 2rem;
    margin-top: 0;
    padding-top: 5px;
    text-transform: uppercase;
  }

  /* line 265, ../../app/sass/components/_responsive.scss */
  .boxes-home .box .text-hover .title-box {
    padding-top: 20px;
  }

  /* line 268, ../../app/sass/components/_responsive.scss */
  .boxes-home .box .text-hover .fast-link {
    width: 28px;
  }

  /* line 271, ../../app/sass/components/_responsive.scss */
  .boxes-home .box .text-hover h3 {
    font-size: 1.2rem;
  }

  /* line 274, ../../app/sass/components/_responsive.scss */
  .boxes-home .box p {
    font-size: 1.3rem;
  }

  /* line 277, ../../app/sass/components/_responsive.scss */
  .boxes-home .box .text-hover button {
    margin: 0 auto;
    font-size: 1.3rem;
  }

  /* line 281, ../../app/sass/components/_responsive.scss */
  .testimonials .carousel.testimonial.slide {
    padding-top: 20px;
  }
}
@media (max-width: 767px) {
  /* line 286, ../../app/sass/components/_responsive.scss */
  .boxes-home .box {
    margin: 25px 15px;
  }

  /* line 290, ../../app/sass/components/_responsive.scss */
  .testimonials .carousel .carousel-inner {
    width: 100%;
  }

  /* line 294, ../../app/sass/components/_responsive.scss */
  .testimonials .carousel .carousel-control {
    display: none;
  }

  /* line 297, ../../app/sass/components/_responsive.scss */
  .list-news li .info-new h3 {
    display: table;
    width: 100%;
  }

  /* line 301, ../../app/sass/components/_responsive.scss */
  .navbar-brand {
    padding: 5px 50px;
    height: 98px;
  }

  /*.navbar-brand::after{
      width: 50%;
      border-left: 17.5rem solid transparent;
      border-right: 17.5rem solid transparent;
      border-top: 21px solid rgba(255, 255, 255, 0.98);
      margin-top: 4px;
      margin-left: -52px;
  }*/
  /* line 315, ../../app/sass/components/_responsive.scss */
  #bg-logo {
    background-color: transparent;
    border-left: 18rem solid transparent;
    border-right: 18.1rem solid transparent;
    border-top: 28px solid rgba(255, 255, 255, 0.98);
    margin-top: 98px;
  }
}
@media (max-width: 668px) {
  /* line 327, ../../app/sass/components/_responsive.scss */
  .navbar-brand, .navbar-brand.scroll2 {
    padding: 2px 11px !important;
    height: 50px !important;
    top: 0px !important;
    width: 150px !important;
  }

  /* line 334, ../../app/sass/components/_responsive.scss */
  .navbar-brand::after, .navbar-brand.scroll2::after {
    border: none !important;
  }

  /* line 338, ../../app/sass/components/_responsive.scss */
  #bg-logo {
    background: none !important;
    border: none !important;
    height: 0;
    width: 0;
  }
}
@media (min-width: 370px) and (max-width: 480px) {
  /* line 347, ../../app/sass/components/_responsive.scss */
  .navbar-brand {
    padding: 2px 11px;
    height: 50px;
  }

  /* line 352, ../../app/sass/components/_responsive.scss */
  .navbar-brand::after {
    border-left: 9rem solid transparent;
    border-right: 9rem solid transparent;
  }

  /* line 357, ../../app/sass/components/_responsive.scss */
  #bg-logo {
    background-color: transparent;
    border: none;
    display: none;
    width: 0;
  }
}
@media (max-width: 480px) {
  /* line 366, ../../app/sass/components/_responsive.scss */
  #navbar {
    height: auto !important;
  }
}
/* Noticias */
@media (max-width: 991px) {
  /* line 374, ../../app/sass/components/_responsive.scss */
  .list-news li > h4 {
    display: block;
    width: 100%;
  }
}
/* Regular Classes */
@media (max-width: 768px) {
  /* line 382, ../../app/sass/components/_responsive.scss */
  .summary a.accordeon-link {
    display: block;
    width: 100%;
    z-index: 1000;
  }
}
